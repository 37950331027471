<ng-container *ngIf="!error">
    <div class="container-main" *ngIf="bandera === false">
        <header>
            <img src="../../../../../assets/images/logo_ticket.svg" class="logo_ticket" alt="logo">
            <div class="img_fragil" *ngIf="is_fragile">
                <img src="../../../../../assets/icons/fragile.svg" class="grafile_icon" alt="fragile">
            </div>
            <div class="img_size">
                <span>{{this.TicketResponse.locker.compartment.compartment_name}}</span>
            </div>
        </header>
        <div class="grid_origin_destiny">
            <div class="container_origin">
                <div class="flex-between">
                    <h2> {{'Origen'}} </h2>
                    <h2> {{this.TicketResponse.locker.locker_origin.zone}} </h2>
                </div>
                <div class="container_p">
                    <p class="name_p"> {{this.TicketResponse.consumer.name_consumer_source}} </p>
                    <p> {{this.TicketResponse.locker.locker_origin.locker_name}} </p>
                    <p class="address_p"> {{this.TicketResponse.locker.locker_origin.address}} </p>
                </div>
            </div>
            <div class="hr_vertical"></div>
            <div class="container_destiny">
                <div class="flex-between">
                    <h2> {{'Destino'}} </h2>
                    <h2> {{this.TicketResponse.locker.locker_destination.zone}} </h2>
                </div>
                <div class="container_p">
                    <p class="name_p"> {{this.TicketResponse.consumer.name_consumer_destination}} </p>
                    <p> {{this.TicketResponse.locker.locker_destination.locker_name}} </p>
                    <p class="address_p"> {{this.TicketResponse.locker.locker_destination.address}} </p>
                </div>
            </div>
        </div>
        <div class="container_w01">
            <div class="w01">
                <h2>{{this.TicketResponse.warehouse.warehouse_name}}</h2>
                <div class="w01_subcontainer">
                    <div>
                        <p class="date_imp">{{'Tracking:'}} {{this.TicketResponse.tracking_number}}</p>
                        <p class="date_imp">{{'Fecha de impresion:'}} {{this.TicketResponse.printing_date}}</p>
                    </div>
                    <div>
                        <p class="date_imp">{{'Dimensiones:'}} {{this.TicketResponse.locker.compartment.compartment_dimension}}</p>
                        <p class="date_imp">{{'Peso aprox:'}} {{this.TicketResponse.locker.compartment.approximate_weight}}kg</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w01_block2">
            <h3> {{this.TicketResponse.package.qr_cedis}} </h3>
        </div>
        <div class="footerTicket">
            <div class="container_qr_2">
                <canvas class="canvasQrOrigin fittoparent" #qrCodeCanvasDestino width="55" height="55" style="width: 55px; height: 55px;"></canvas>
            </div>
        </div>
    </div>
    
    <div class="container-main-small" *ngIf="bandera === true">
        <div class="logo-vivipost">
            <div class="text">
                <img src="../../../../assets/images/vivipost-box.svg" alt="">
                {{this.TicketResponse.tracking_number}}
            </div>
        </div>
        <div class="order-information">
            <div class="qr-box">
                <div class="img-qr-small">
                    <canvas #qrCodeCanvasOrigenSmall class="fittoparent"></canvas>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="error">
    <ng-container *ngIf="error === 500">
        <div class="main_container">
            <div class="error_container">
                <div class="error_txt1">Error 500</div>
                <div class="error_txt2">Página no disponible</div>
                <div class="error_txt3">Parece ser que tuvimos un problema, pero no te preocupes. Si el problema persiste comunícate con tu superior en almacén para que te diga como seguir con tus entregas.</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="error === 'INVALID_TOKEN'">
        <div class="main_container">
            <div class="error_container">
                <div class="error_txt1">Tuvimos un problema</div>
                <div class="error_txt2">Token incorrecto</div>
                <div class="error_txt3">Parece que tuvimos un problema al mostrar el token que necesitas. Si el problema persiste comunícate con tu superior en almacén para que te diga como seguir con tus entregas.</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="error === 'INVALID_STATUS'">
        <div class="main_container">
            <div class="error_container">
                <div class="error_txt1">Tuvimos un problema</div>
                <div class="error_txt2">Estatus incorrecto</div>
                <div class="error_txt3">Parece que este paquete tiene un estatus que no permite imprimir la etiqueta. Si el problema persiste comunícate con tu superior en almacén para que te diga como seguir con tus entregas.</div>
            </div>
        </div>
    </ng-container>
</ng-container>