import { JSONParser } from '@amcharts/amcharts4/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
	private token: string;
	public temp: any;
	public data;
	public baseUrl: string = environment.url_server_backend;

	constructor(private http: HttpClient){}

	setToken(token: string) {
		this.token = token;
	}
	getToken() {
		return this.token ? this.token : localStorage.getItem('token');
	}

	async updateLockerWarehouse(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/locker/update-locker-warehouse';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async validatePackageCedis(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/package/validate-package-cedis';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async registerPackageCedis(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/package/register-package-cedis';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async assignLockerPackage(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl + '/server/package/assign-locker-package';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async queryLockerWarehouse(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/locker/query-locker-warehouse';
		return await this.http.get(url,options).toPromise();
	}

	async queryReviewFields(){
		// let options ={
		// 	headers:{
		// 		'Content-Type': 'application/json',
		// 		token: this.getToken()
		// 	},
		// 	params: params
		// }
		let url = this.baseUrl +'/server/package/query-review-fields';
		return await this.http.get(url).toPromise();
	}
	async reviewPackage(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params: params
		}
		let url = this.baseUrl +'/server/package/review-package';
		return await this.http.post(url,params).toPromise();
	}

	async returnPackage(params){
		let url = this.baseUrl +'/server/package/return-package';
		return await this.http.post(url,params).toPromise();
	}

	async queryPackageWarehouse(params){
		let options ={
			headers:{
				'Content-Type': 'application/json',
				token: this.getToken()
			},
			params:params
		}
		let url = this.baseUrl +'/server/package/query-package-warehouse';
		return await this.http.get(url,options).toPromise();
	}

	async getDataOfTicket(tokenWarehouse,params){
		let url = this.baseUrl +'/server/integration/create-tickets/'+tokenWarehouse;
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body);
		const response = await this.http.request(request).toPromise();
		return response;
	}


	async collectPackageInCedis(params){
		const headers = new HttpHeaders({
			'Content-Type':'application/json',
			token:this.getToken()
		})
		let url =  this.baseUrl +'/server/package/collect-package-cedis';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this.http.request(request).toPromise();
		return response;
	}

	async getNotificationsPackets(params: any) {

		// Construye un objeto HttpParams con los parámetros
		let httpParams = new HttpParams();
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				httpParams = httpParams.set(key, params[key]);
			}
		}

		// Agrega los encabezados necesarios
		let options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.getToken(),
			}),
			params: httpParams, // Agrega los parámetros a la solicitud
		};

		let url = this.baseUrl + '/server/system/notification/get-notification-packet';

		// Realiza la solicitud GET con los parámetros en la URL
		return this.http.get(url, options).toPromise();
	}

}
