<!-- *locker --> 
<div fxLayout="row" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutGap="5px" class="grid_lockers">
        <div class="module_lockers"  *ngFor="let lockers_array of module">
          <div *ngFor="let locker of lockers_array.locker.template_module" 
          fxFlex="{{ (locker[0].class == 'LOCKER_BLOCK')  ?  '10px' : '93px'}}" 
          fxFlex.sm="{{ (locker[0].class == 'LOCKER_BLOCK') ?  '0.6vw' : '85.9px'}}"
          fxFlex.xs="{{ (locker[0].class == 'LOCKER_BLOCK') ?  '0.6vw' : '10vw'}}">         
             <mat-grid-list cols="1" rowHeight="15px" gutterSize="5px">
                <mat-grid-tile
                  *ngFor="let d of locker"
                  [colspan]="d.cols"
                  [rowspan]="d.rows"
                  class="{{d.class}} {{ (d.class=='LOCKER_CERRADO' && select_compartment) ? 'select':'' }}"
                  [matTooltipClass]="safari?'safari-tip':'grid-tip'"
                  matTooltipPosition="above"
                  [matTooltip]="itemToolInfo(d)"
                  (click)="(d.class=='LOCKER_CERRADO' && select_compartment) ?  onSelectCompartment($event, d) : ''"
                  >

                    <div *ngIf="d.class != 'SCREEN' && d.class != 'LOCKER_BLOCK'" class="number_locker"> {{d.text}} </div>
                    <div *ngIf="d.rows == 5 && d.class == 'SCREEN'">
                      <div style="width: 55px; height: 95px; padding-top: 4px; margin-left:20px" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">                            
                        <div style="height: 58px; width: 81%; background: #000; ">
                            <img src="{{img}}" width="100%" height="58px" (click)="viewScreenShot(img)">
                        </div>
                        <div style="height: 2px; width: 2px; position: absolute; border: solid 1px #000; background: #000;top: 35%; left: 20%;"></div>
                        <div style="height: 4px; width: 4px; position: absolute; border: solid 1px #000; background: #000;top: 45%; left: 16%;"></div>
                        <div style="height: 8px; width: 11px; position: absolute; border: solid 1px #000; background: #000;top: 55%; left: 12%;"></div>
                      </div> 
                    </div>
                </mat-grid-tile>
             </mat-grid-list>
          </div>
        </div>
     </div> 
</div>
<!-- <div fxLayout="row" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center" class="grid" *ngIf="lockerID !== '1'">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div *ngFor="let m of module" class="grid_lockers">
      <mat-grid-list 
        fxFlex="100%" 
        rowHeight="0.9vw"
        fxFlex="{{ (m.locker.name_locker_module==='CM-2') ? 43 : (m.locker.name_locker_module==='LOCKER_BLOCK') ? 13 : 98 }}px" 
        [cols]="(m.locker.name_locker_module==='CM-2') ? 1 : 6" 
        gutterSize="{{ (m.locker.name_locker_module==='CM-2' || m.locker.name_locker_module==='LOCKER_BLOCK') ? 1 : 2 }}" 
        class="(m.locker.name_locker_module==='LOCKER_BLOCK') ? grid_lockers_block : grid_locker ?"
        fxFlex.sm="6vw" 
        fxFlex.xs="7vw"
        >                
          <div *ngFor="let l of m.locker.template_module">
            <mat-grid-tile  
              [colspan]="l.cols"
              [rowspan]="l.rows"
              [ngClass]="l.class"
              [matTooltipClass]="safari?'safari-tip':'grid-tip'"
              matTooltipPosition="above"
              [matTooltip]="itemToolInfo(l)"
              (click)="(l.class=='LOCKER_CERRADO' && select_compartment) ?  onSelectCompartment($event, l) : ''"
              >
              <div *ngIf="l.class != 'SCREEN' && l.class != 'LOCKER_BLOCK'" class="number_locker"> {{l.text}} </div>
              <div *ngIf="l.class == 'SCREEN'" style="border: 0.5px solid #141414; width: 35px; height: 60px; padding-top: 4px;" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div style="height: 20px; width: 65%; border: 0.5px solid #141414;"></div>
                <div style="height: 2px; width: 11px; border: 0.5px solid #141414;"></div>
              </div>
            </mat-grid-tile>
          </div>
        
      </mat-grid-list>
    </div>
  </div>
</div> -->

<!-- *leyenda -->
<!-- <div fxLayout="column" fxLayoutAlign="center center">    
<div fxFlex class="legende" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px">
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #9EC57C;"> </div><p>{{ 'lockers.available_door' | translate }} ({{tt_available}})</p></div>
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #ff0000;"> </div><p>{{ 'lockers.busy_door' | translate }} ({{tt_occupied}})</p></div>
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #ffda1a;"> </div><p>{{ 'lockers.fail_door' | translate }} ({{tt_damaged}})</p></div>
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #0067B3;"> </div><p>{{ 'lockers.door_open' | translate }} ({{tt_open}})</p></div>
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #f6821f;"> </div><p>{{ 'lockers.expired' | translate }} ({{tt_expired}})</p></div>
        <div fxLayoutAlign="space-evenly center"><div class="circle" style="background-color: #55A5E0;"> </div><p>{{ 'lockers.tt_schedule' | translate }} ({{tt_schedule}})</p></div>
</div>
</div> -->