import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../services/user.service';
import { EncryptDataService } from '../security/encypt-data.service';

@Injectable({
	providedIn: 'root',
})
export class WarehouseGuard implements CanActivate {
	constructor(
		private us: UserService,
		private router: Router,
		private securityLocalStorage: EncryptDataService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		try {
			// Obtener y desencriptar los datos almacenados en localStorage
			const encryptedData = localStorage.getItem('data');
			if (!encryptedData) {
				this.router.navigate(['/403']); // Redirigir si no hay datos
				return false;
			}

			const data = JSON.parse(this.securityLocalStorage.decryptData(encryptedData));
			// Verificar el rol del usuario
			if (data.result.id_user_type === 6) {
				return true;
			} else {
				this.router.navigate(['/403']); // Redirigir si el rol no es adecuado
				return false;
			}
		} catch (error) {
			console.error('Error al verificar el acceso:', error);
			this.router.navigate(['/403']); // Redirigir en caso de error
			return false;
		}
	}
}
