import {
	Component,
	ElementRef,
	OnInit,
	ViewChild,
	AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WarehouseService } from 'src/app/core/http/warehouse/warehouse.service';
import {
	DataReturnTicketInterface,
	TicketReturn,
} from 'src/app/core/models/warehouse/warehouse.interface';
import * as QRCode from 'qrcode';

@Component({
	selector: 'app-create-tickets',
	templateUrl: './create-tickets.component.html',
	styleUrls: ['./create-tickets.component.scss'],
})
export class CreateTicketsComponent implements OnInit, AfterViewInit {
	ticketToken: string;
	TicketResponse: DataReturnTicketInterface;
	is_fragile: boolean = false;
	bandera: boolean = false;
	printing_date;
	error;

	@ViewChild('qrCodeCanvasCEDIS', { static: false })
	qrCodeCanvasCEDIS: ElementRef;
	@ViewChild('qrCodeCanvasCEDISSmall', { static: false })
	qrCodeCanvasCEDISSmall: ElementRef;
	@ViewChild('qrCodeCanvasOrigen', { static: false })
	qrCodeCanvasOrigen: ElementRef;
	@ViewChild('qrCodeCanvasOrigenSmall', { static: false })
	qrCodeCanvasOrigenSmall: ElementRef;
	@ViewChild('qrCodeCanvasDestino', { static: false })
	qrCodeCanvasDestino: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private warehouseSvc: WarehouseService,
		private navigationRoute: Router
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.ticketToken = params.get('tokenTicket');
		});
		localStorage.setItem('token', 'tickets ' + this.ticketToken);

		this.route.queryParams.subscribe((params) => {
			this.bandera = params['c'] !== '1';
		});

		let header = {
			language: localStorage.getItem('language'),
		};

		this.warehouseSvc.getDataOfTicket(this.ticketToken, header).then((res: any) => {
				if (res.body.mensaje_return.ERROR) {
					this.error = res.body.mensaje_return.CODE;
					return;
				}

				this.error = null;
				this.TicketResponse = res.body.data_return;
				this.is_fragile = this.TicketResponse.package.is_fragile;
				this.printing_date = res.body.data_return.printing_date;
			}).catch(err => {
				this.error = 500;
			});
	}

	ngAfterViewInit() {
		if (this.qrCodeCanvasCEDIS) {
			const qrCodeDataCEDIS = this.TicketResponse.tracking_number;
			QRCode.toCanvas(
				this.qrCodeCanvasCEDIS.nativeElement,
				qrCodeDataCEDIS,
				{ width: 200, height: 200 },
				(error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				}
			);
		}

		if (this.qrCodeCanvasCEDISSmall) {
			const qrCodeDataCEDIS = this.TicketResponse.tracking_number;
			QRCode.toCanvas(
				this.qrCodeCanvasCEDISSmall.nativeElement,
				qrCodeDataCEDIS,
				{ width: 113, height: 113 },
				(error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				}
			);
		}

		if (this.qrCodeCanvasOrigen) {
			const qrCodeDataOrigen = this.TicketResponse.package.qr_pickup;
			QRCode.toCanvas(
				this.qrCodeCanvasOrigen.nativeElement,
				qrCodeDataOrigen,
				{ width: 55, height: 55 },
				(error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				}
			);
		}

		if (this.qrCodeCanvasOrigenSmall) {
			const qrCodeDataOrigen = this.TicketResponse.package.qr_pickup;
			QRCode.toCanvas(
				this.qrCodeCanvasOrigenSmall.nativeElement,
				qrCodeDataOrigen,
				{ width: 55, height: 55 },
				(error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				}
			);
		}

		if (this.qrCodeCanvasDestino) {
			const qrCodeDataDestino = this.TicketResponse.package.qr_delivery;
			QRCode.toCanvas(
				this.qrCodeCanvasDestino.nativeElement,
				qrCodeDataDestino,
				{ width: 55, height: 55 },
				(error) => {
					if (error) {
						console.error('Error generating QR code:', error);
					}
				}
			);
		}
	}
}
