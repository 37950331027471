import { Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Interceptor } from './core/interceptors/interceptor.service';
import { DatePipe } from '@angular/common';
import { EncryptDataService } from './core/security/encypt-data.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnChanges {
	//title = 'DASHBOARD';
	language: string = 'es';
	mode: string = localStorage.getItem('mode') || 'light';

	id: number;
	lat: number;
	lon: number;
	version: string = 'v1.1.0.0';
	time;

	modeChange(mode: string): void {
		this.mode = mode;
		document.body.classList.toggle('dark-mode', this.mode === 'dark');
		localStorage.setItem('mode', mode);
	}

	constructor(
		private translate: TranslateService,
		private router: Router,
		private interceptor: Interceptor,
		private datePipe: DatePipe,
		public securityLocalStorage :EncryptDataService
	) {
		if (
			localStorage.getItem('language') &&
			localStorage.getItem('language') != '' &&
			localStorage.getItem('language') != 'undefine'
		) {
			this.language = localStorage.getItem('language');
		}
		translate.setDefaultLang(this.language.toLowerCase());
		localStorage.setItem('language', this.language);
		localStorage.setItem('version', this.version);
		this.doSomething(this);
		if (this.mode === 'dark') {
			document.body.classList.add('dark-mode');
		}
	}

	// OnInit
	ngOnInit() {

	}
	ngOnChanges(changes: SimpleChanges): void {
		let local = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		if(local.token){
			if(local.result.id_user_type === 6){
				this.router.navigateByUrl('/warehouse/locker');
			}
			else if(local.result.id_user_type ===7){
				this.router.navigateByUrl('/vivipost/overview');
			}
			else if(local.result.id_user_type ===9){
				this.router.navigateByUrl('/superadmin/overview');
			}
		}
		else{
			this.router.navigateByUrl('/auth/new-login');
		}
	}

	setId(id) {
		this.id = id;
	}
	getId() {
		return this.id;
	}

	move() {
		clearTimeout(this.time);
		this.time = setTimeout(() => {
			this.navigate();
		}, 600000);
	}

	navigate() {


		this.router.navigateByUrl('login');
	}

	@HostListener('window:beforeunload', ['$event'])
	public doSomething($event) {
		if (this.interceptor.getToken()) {
			this.disconectVideoCam();
		}
	}

	async disconectVideoCam() {
		const data = {
			language: localStorage.getItem('language'),
		};
		//TODO:DESCOMENTAR Y ARREGLAR
		//  await this.adminService.disconectVideoCam(data);
	}
}
