//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
//Modules
import { MaterialModule } from './libraries/angular-material/material.module';
// Pipes
import { CreateTicketsComponent } from './components/create-tickets/create-tickets.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateOriginalPipe } from './pipes/date-original.pipe';
import { HourOriginalPipe } from './pipes/hour-original.pipe';
import { OrderNumberPipe } from './pipes/order-number.pipe';
import { PhoneFormatPipe } from './pipes/phoneFormat.pipe';
import { SearchPipe } from './pipes/search.pipe';
// Libraries
import { AgGridModule } from 'ag-grid-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';
import { WordSlicePipe } from './pipes/word-slice.pipe';
//Components
// import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonCreateCarrierComponent } from './components/button-create-carrier/button-create-carrier.component';
import { ButtonCreateOrderComponent } from './components/button-create-order/button-create-order.component';
import { ButtonCreateUserComponent } from './components/button-create-user/button-create-user.component';
import { ButtonExportComponent } from './components/button-export/button-export.component';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { ButtonUploadComponent } from './components/button-upload/button-upload.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CardBrandComponent } from './components/card-brand/card-brand.component';
import { CardComponent } from './components/card/card.component';
import { CardDoorsComponent } from './components/card-doors/card-doors.component';
import { CardKpiComponent } from './components/card-kpi/card-kpi.component';
import { CardsLockersComponent } from './components/cards-lockers/cards-lockers.component';
import { CardsOverviewComponent } from './components/cards-overview/cards-overview.component';
import { CustomLoadingCellRenderedComponent } from './components/custom-loading-cell-rendered/custom-loading-cell-rendered.component';
import { DateFormatWithoutHourPipe } from './pipes/date-format-without-hour.pipe';
import { DateTextTablesComponent } from './components/date-text-tables/date-text-tables.component';
import { DoorStatusGenerateTockenComponent } from './components/door-status-generate-tocken/door-status-generate-tocken.component';
import { DropdownExportComponent } from './components/dropdown-export/dropdown-export.component';
import { DropdownNavbarComponent } from './components/dropdown-navbar/dropdown-navbar.component';
import { FilterFunnelComponent } from './components/filter-funnel/filter-funnel.component';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleMapsComponent } from './libraries/google-maps/google-maps.component';
import { LockerGridGenerateTockenComponent } from './components/locker-grid-generate-tocken/locker-grid-generate-tocken.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { SidebarSettingsComponent } from './components/sidebar-settings/sidebar-settings.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { StarsComponent } from './components/stars/stars.component';
import { TableComponent } from './components/table/table.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TooltipCenterSmComponent } from './components/tooltip-center-sm/tooltip-center-sm.component';
import { TooltipCollectionComponent } from './components/tooltip-collection/tooltip-collection.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipEndSmComponent } from './components/tooltip-end-sm/tooltip-end-sm.component';
import { TooltipTableComponent } from './components/tooltip-table/tooltip-table.component';
import { CustomLoaderTemplateComponent } from './components/custom-loader-template/custom-loader-template.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { InputComponent } from './components/input/input.component';
// import { AlertsComponentComponent } from './components/alert-component/alerts-component.component';
// import { AlertComponent } from './components/alert/alert.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TablesComponent } from './components/dashboard-components/tables/tables.component';
import { InputSearchComponent } from './components/dashboard-components/input-search/input-search.component';
import { PaginationSizeSelectorComponent } from './components/dashboard-components/pagination-size-selector/pagination-size-selector.component';
import { PaginationComponent } from './components/dashboard-components/pagination/pagination.component';
import { CloseButtonComponent } from './components/dashboard-components/close-button/close-button.component';
import { CalendarDashboardComponent } from './components/dashboard-components/calendar/calendar.component';
import { ButtonDashboardComponent } from './components/dashboard-components/button/button.component';
import { CalendarModule } from 'primeng/calendar';
import { ContentSwitcherComponent } from './components/dashboard-components/content-switcher/content-switcher.component';
import { AlertComponent } from './components/dashboard-components/alert/alert.component';
import { DropdownComponent } from './components/dashboard-components/dropdown/dropdown.component';
import { ItemNotificationComponent } from './components/item-notification/item-notification.component';
import { ToggleDashboardComponent } from './components/dashboard-components/toggle-dashboard/toggle-dashboard.component';
import { InputDashboardComponent } from './components/dashboard-components/input-dashboard/input-dashboard.component';
import { ChangePasswordComponent } from './components/dashboard-components/change-password/change-password.component';
import { Page403Component } from './pages/page403/page403.component';
import { Page404Component } from './pages/page404/page404.component';
import { Page500Component } from './pages/page500/page500.component';
import { Page503Component } from './pages/page503/page503.component';
import { LinkComponentComponent } from './components/dashboard-components/link-component/link-component.component';
import { SidebarDashboardComponent } from './components/dashboard-components/sidebar-dashboard/sidebar-dashboard.component';
import { StatusTopicAuditComponent } from './components/dashboard-components/status-topic-audit/status-topic-audit.component';
@NgModule({
	declarations: [
		// AlertMessageComponent,
		ButtonComponent,
		ButtonCreateCarrierComponent,
		ButtonCreateOrderComponent,
		ButtonCreateUserComponent,
		ButtonExportComponent,
		ButtonIconComponent,
		ButtonUploadComponent,
		CalendarComponent,
		CardBrandComponent,
		CardComponent,
		CardDoorsComponent,
		CardKpiComponent,
		CardsLockersComponent,
		CardsOverviewComponent,
		CreateTicketsComponent,
		CustomLoadingCellRenderedComponent,
		DateFormatPipe,
		DateFormatWithoutHourPipe,
		DateOriginalPipe,
		DateTextTablesComponent,
		DoorStatusGenerateTockenComponent,
		DropdownExportComponent,
		DropdownNavbarComponent,
		FilterFunnelComponent,
		FooterComponent,
		GoogleMapsComponent,
		HourOriginalPipe,
		LockerGridGenerateTockenComponent,
		NavbarComponent,
		NotificationsComponent,
		OrderNumberPipe,
		PhoneFormatPipe,
		SearchPipe,
		SidebarComponent,
		SidebarMenuComponent,
		SidebarSettingsComponent,
		StarRatingComponent,
		StarsComponent,
		TableComponent,
		ToggleComponent,
		TooltipCenterSmComponent,
		TooltipCollectionComponent,
		TooltipComponent,
		TooltipEndSmComponent,
		TooltipTableComponent,
		WordSlicePipe,
		HeaderMenuComponent,
		CustomLoaderTemplateComponent,
		InputComponent,
		// AlertsComponentComponent,
		// AlertComponent,


		TablesComponent,
		InputSearchComponent,
		PaginationSizeSelectorComponent,
		PaginationComponent,
		// AlertComponent,
		CloseButtonComponent,
		CalendarDashboardComponent,
		ButtonDashboardComponent,
		HeaderMenuComponent,
		ContentSwitcherComponent,
		AlertComponent,
		DropdownComponent,
		ItemNotificationComponent,
		ToggleDashboardComponent,
		InputDashboardComponent,
		ChangePasswordComponent,
		Page403Component,
		Page404Component,
		Page500Component,
		Page503Component,
		LinkComponentComponent,
		SidebarDashboardComponent,
		StatusTopicAuditComponent
	],
	exports: [
		// AlertMessageComponent,
		ButtonComponent,
		ButtonCreateCarrierComponent,
		ButtonCreateOrderComponent,
		ButtonCreateUserComponent,
		ButtonExportComponent,
		ButtonIconComponent,
		ButtonUploadComponent,
		CalendarComponent,
		CardBrandComponent,
		CardComponent,
		CardDoorsComponent,
		CardKpiComponent,
		CardsLockersComponent,
		CardsOverviewComponent,
		CreateTicketsComponent,
		CustomLoadingCellRenderedComponent,
		DateFormatPipe,
		DateFormatWithoutHourPipe,
		DateOriginalPipe,
		DateTextTablesComponent,
		DoorStatusGenerateTockenComponent,
		DropdownExportComponent,
		DropdownNavbarComponent,
		FilterFunnelComponent,
		FooterComponent,
		GoogleMapsComponent,
		HourOriginalPipe,
		LockerGridGenerateTockenComponent,
		NavbarComponent,
		NotificationsComponent,
		OrderNumberPipe,
		PhoneFormatPipe,
		SearchPipe,
		SidebarComponent,
		SidebarMenuComponent,
		SidebarSettingsComponent,
		StarRatingComponent,
		StarsComponent,
		TableComponent,
		ToggleComponent,
		TooltipCenterSmComponent,
		TooltipCollectionComponent,
		TooltipComponent,
		TooltipEndSmComponent,
		TooltipTableComponent,
		WordSlicePipe,
		CustomLoaderTemplateComponent,
		HeaderMenuComponent,
		InputComponent,
		// AlertsComponentComponent,
		// AlertComponent,
		LoaderComponent,

		TablesComponent,
		InputSearchComponent,
		PaginationSizeSelectorComponent,
		PaginationComponent,
		// AlertComponent,
		CloseButtonComponent,
		CalendarDashboardComponent,
		ButtonDashboardComponent,
		HeaderMenuComponent,
		ContentSwitcherComponent,
		AlertComponent,
		DropdownComponent,
		ItemNotificationComponent,
		InputDashboardComponent,
		ChangePasswordComponent,
		LinkComponentComponent,
		SidebarDashboardComponent,
		StatusTopicAuditComponent
	],
	imports: [
		AgGridModule,
		CalendarModule,
		CommonModule,
		MaterialModule,
		NgApexchartsModule,
		NgxPaginationModule,
		RouterModule,
		TranslateModule,
		RouterOutlet,
		RouterLink,
		RouterLinkActive,
		LoaderComponent
	],
})
export class SharedModule {}
