import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appPhoneFormat]'
})
export class PhoneFormatDirective {
	constructor(private el: ElementRef) {}
	@HostListener('input')
	onInput() {
	let value = this.el.nativeElement.value;
	value = this.formatPhoneNumber(value);
	this.el.nativeElement.value = value;
	}
	private formatPhoneNumber(value: string): string {
		// Elimina cualquier caracter que no sea un número
		value = value.replace(/\D/g, '');
		// Aplica el formato del número de teléfono (ejemplo: "81-1732-8312")
		const areaCode = value.slice(0, 2);
		const firstPart = value.slice(2, 5);
		const secondPart = value.slice(5,8);
		const thirdPart = value.slice(8,12);
		return `+${areaCode} ${firstPart}-${secondPart}-${thirdPart}`;
	}
}
