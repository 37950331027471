import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { LoginForm } from '../models/users.interfaces';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/modules/auth/services/user-auth.service';
import { EncryptDataService } from '../security/encypt-data.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	// Declarar las propiedades públicas de la clase
	public baseurl: string = environment.url_server_backend;
	private token: string;
	public data;

	constructor(
		private _http: HttpClient,
		private router: Router,
		private userService: UserAuthService,
		private securityLocalStorage: EncryptDataService
	) {
		localStorage.removeItem('url_cam_event');
	}

	getToken():string {
		let data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		return data.token;
	}

	// Método para obtener el tipo de usuario actual que se usara como guard en core/guards
	get role() {
		let data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')))
		return data.result.id_user_type;
	}

	login(formData: LoginForm) {
		const url = this.baseurl + '/server/user/validate-user-password';
		return this._http
			.post<any>(url, JSON.stringify(formData))
			.pipe(retry(1));
	}
	// Método que establece el valor de la propiedad 'data'
	setData(data) {
		this.data = data;
	}
	//* TEST
	// LOGOUT
	logout() {
		this.userService.getSystemVersion().then((res:any)=>{
			if(res.mensaje_return.ERROS_MENSAGGE ==='SUCCESS'){
				localStorage.setItem('version',res.version);
			}
		})
		localStorage.setItem('language','es');
		localStorage.setItem('mode','light');
		this.router.navigateByUrl('auth/new-login');
		location.reload();
	}



	async getNotifications(params: any): Promise<any> {
		let options = { params };
		let url = this.baseurl + '/server/logs/log-order';
		return await this._http.get(url, options).toPromise();
	}

	async getListTypeUser(params:any):Promise<any>{
		let options = {params};
		let url = this.baseurl + '/server/user/list-type-user';
		return await this._http.get(url,options).toPromise()
	}

	async getCodeMaster(params){
		let options={
			header:{
				'Content-Type':'application/json',
				token:this.getToken()
			},
			params:params
		}
		let url = this.baseurl + '/server/user/get-code-master';
		return await this._http.get(url,options).toPromise();
	}

	async updateCodeMaster(params){
		const headers = new HttpHeaders();
		headers.set('token',this.token);
		let url = this.baseurl + '/server/user/update-code-master';
		const body = JSON.stringify(params);
		const request = new HttpRequest('POST',url,body,{headers});
		const response = await this._http.request(request).toPromise();
		return response;
	}
}
