import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EncryptDataService } from '../security/encypt-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root',
})
export class Interceptor implements HttpInterceptor {
	private token: string;

	constructor(
		private router: Router,
		private securityLocalStorage: EncryptDataService,
		private toast: ToastrService,
		private dialog: MatDialog) { }

	setToken(token: string) {
		this.token = token;
	}

	getToken() {
		if (localStorage.getItem('data') !== null) {
			let data = JSON.parse(this.securityLocalStorage.decryptData(localStorage.getItem('data')));
			return data.token;
		} else {
			return null;
		}
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers = new HttpHeaders({
		'Content-Type': 'application/json',
		});

		const token = this.getToken();
		if (token) {
		headers = headers.set('token', token);
		}

		const reqClone = req.clone({
		headers,
		});

		return next.handle(reqClone).pipe(
		catchError((error: HttpErrorResponse) => this.handleError(error))
		);
	}

	private handleError(error: HttpErrorResponse): Observable<never> {
		if (error.error instanceof ErrorEvent) {
			// Errores del cliente (por ejemplo, red no disponible, CORS)
			console.error('Error del cliente:', error.error.message);
		}
		else {
			// El servidor retornó un código de error
			this.dialog.closeAll();
			console.error(`Código de error ${error.status}, ` + `mensaje: ${error.message}`);
			// Puedes agregar lógica adicional aquí para manejar errores específicos
			if (error.status === 401) {
				// Manejar la autenticación no autorizada (por ejemplo, redirigir a la página de inicio de sesión)
				this.router.navigate(['/auth/new-login']);
			}
			else if (error.status === 403) {
				if (error.url.includes('/server/integration/create-tickets')) {
					return;
				}
				if (!localStorage.getItem('tokenExpiredToastShown')) {
					this.router.navigate(['/auth/new-login']);
					this.toast.error('Intenta ingresar de nuevo', 'Alguien más se ha conectado a tu cuenta', {
						toastClass: 'toast-erronea',
						positionClass: 'toast-bottom-left',
					});
					localStorage.setItem('tokenExpiredToastShown', 'true');
				}
				this.router.navigate(['/auth/new-login']);
			}
			else if (error.status === 500 && error.error && error.error.message && error.error.message.includes('Clave primaria no válida')) {
				// Manejar el error específico relacionado con la clave primaria incorrecta
				console.error('Error: Clave primaria incorrecta');
				// Puedes tomar acciones adicionales aquí según tus necesidades
			}
		}
		// Retorna un observable con un mensaje de error legible
		return throwError('Ocurrió un error. Por favor, inténtelo de nuevo más tarde.');
	}
}
