import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardDragDropComponent } from './components/card-drag-drop/card-drag-drop.component';
import { SharedModule } from '../shared.module';
import { TooltipTestComponent } from './components/tooltip-test/tooltip-test.component';
import { TableNewComponent } from './components/table-new/table-new.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { ConfigWebhookComponent } from './components/config-webhook/config-webhook.component';
import { ActivityComponent } from './components/activity/activity.component';
import { BtnActivityComponent } from './components/btn-activity/btn-activity.component';
import { AgGridModule } from 'ag-grid-angular';
import { DialogSeeJsonWebhookComponent } from './components/dialogs/dialog-see-json-webhook/dialog-see-json-webhook.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { CodeWithLineNumbersComponent } from './components/code-with-line-numbers/code-with-line-numbers.component';
import { CheckboxSelectionEventWebhookComponent } from './components/checkbox-selection-event-webhook/checkbox-selection-event-webhook.component';

@NgModule({
	declarations: [
		CardDragDropComponent,
		TableNewComponent,
		TooltipTestComponent,
		IntegrationsComponent,
		ConfigWebhookComponent,
		ActivityComponent,
		BtnActivityComponent,
		DialogSeeJsonWebhookComponent,
		CodeWithLineNumbersComponent,
		CheckboxSelectionEventWebhookComponent,
	],
	exports: [
		CardDragDropComponent,
		TableNewComponent,
		IntegrationsComponent,
		TooltipTestComponent,
		CodeWithLineNumbersComponent,
		CheckboxSelectionEventWebhookComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
		TranslateModule,
		FormsModule,
		AgGridModule,
		MatDialogModule,
		NgxJsonViewerModule,
	]
})
export class TestModule {}
