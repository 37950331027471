import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Locker, Module } from 'src/app/core/models/admin-client.interfaces';
import { DatePipe } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ClientSign } from '../dialogs-admin/client-dialog';
import { AdminClientService } from '../../../../core/http/admin-client.service';

@Component({
	selector: 'app-lockers-client-grid-admin',
	templateUrl: './lockers-client-grid-admin.component.html',
	styleUrls: ['./lockers-client-grid-admin.component.scss'],
})
export class LockersClientGridAdminComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() modulo;
	@Input() cStatus;
	@Input() gridLocalhost;
	@Input() id_locker_select;
	@Input() select_compartment = false;
	@Output() select_compartment_available = new EventEmitter<string>();

	compartment_select: boolean = false;
	arrayNodeLocker: any = [];
	data;
	res;
	tt_open = 0;
	tt_occupied = 0;
	tt_available = 0;
	tt_damaged = 0;
	count_door = 1;
	locker_info: Locker[] = [];
	id_locker: number;
	cols: number[];
	rows: number[];
	lockers: Locker[];
	module: any = [];
	module2: Module[];
	col: number = 0;
	height: number = 0;
	modulos = 0;
	device = false;
	safari: boolean = false;
	grid = [];
	temp = [];
	tt_expired: number = 0;
	tt_schedule: number = 0;
	cols_grid = 6;
	identifi_module: number = 0;
	lockerGrid: any;
	img = 'https://my.vivipost.com/screenshot/VIVPOST/LAB.png';
	compartment_select_current: any;
	disabled_ratio: string = 'true';

	infoLocker=[];

	imgScreen: string = localStorage.getItem('img_screen');

	lockerID = localStorage.getItem('id_locker');

	constructor(
		private datepipe: DatePipe,
		private platform: Platform,
		private adminService: AdminClientService,
		private dialog: MatDialog,
		private translate: TranslateService
	) {}

	ngOnDestroy() {
		this.dialog.closeAll();
	}

	ngOnInit() {
		this.safari = this.platform.SAFARI;
		if (this.platform.ANDROID || this.platform.IOS) {
			this.device = true;
		} else {
			this.device = false;
		}
		let cont_rows = 0;
		let module_add: any = [];
		module_add['template_module'] = [];
		// this.getGrid();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.grid.pop();
		this.grid = JSON.parse(localStorage.getItem('grid'));
		this.grid.forEach((e, i) => {
			e.template_module.forEach((l) => {
				if (l.class === 'NULL' || l.class === 'LOCKER_BLOCK') {
					l.text = 'null';
				} else if (l.class === 'SCREEN') {
					l.text = 'SCREEN';
				} else {
					l.text = this.count_door++;
				}
			});
			this.module.push({
				id: i + 1,
				locker: e,
			});
		});
		if (this.cStatus && this.modulo) {
			this.cStatus = JSON.parse(this.cStatus);
			this.modulo = JSON.parse(this.modulo);
			this.cStatus.forEach((e) => {
				e.name_status == 'CERRADO' ? (this.tt_available = e.quantity) :
				e.name_status == 'ABIERTO' ? (this.tt_open = e.quantity):
				e.name_status == 'FALLO'   ? (this.tt_damaged = e.quantity):
				e.name_status == 'OCUPADO' ? (this.tt_occupied = e.quantity)
					: null;
			});
		}

		this.grid = [];
		this.getGrid();
	}
	ngAfterViewInit() {
	}

	itemToolInfo(l: any) {
		let date = new Date();
		let d = this.datepipe.transform(date, 'dd/MM/y');
		let info;

		if (
			l.class != 'LOCKER_CERRADO' &&
			l.class != 'LOCKER_BLOCK' &&
			l.class != 'SCREEN'
		) {
			if (
				l.order_number == 0 ||
				l.order_number == 'undefined' ||
				!l.order_number
			) {
				info = `Door: ${l.text} \n Status Comparment: ${
					l.class.split('_')[1]
				}`;
			} else if (
				l.class == 'SCREEN' ||
				l.class == 'LOCKER_BLOCK' ||
				l.class == 'NULL'
			) {
				return;
			} else {
				if (l.class) {
					info = `Door: ${l.text} \n Order Number: ${
						l.order_number
					} \n Order: ${l.tracking_number} \n Status Comparment: ${
						l.class.split('_')[1]
					}`;
				}
			}
			return info;
		}
	}

	viewScreenShot(img: string) {
		this.translate
			.get(['general.title_screenShot', 'buttons.cancel'])
			.subscribe((t: any) => {
				let dialogRef = this.dialog.open(ClientSign, {
					width: '50%',
					height: '80%',
					data: {
						image: img,
						title: t['video_tutorial.title_schedule'],
						text_button: t['buttons.cancel'],
						type: 'screenShot',
					},
				});
			});
	}

	async getGrid() {
		this.grid = [];
		let params = {
			language: localStorage.getItem('language').toLowerCase(),
			id_locker: localStorage.getItem('id_locker'),
		};
		// this.lockerGrid = await this.adminService.getLockersGrid(params);
		this.adminService.getLockersGrid(params).then((res:any)=>{
			this.lockerGrid = res;
			this.lockerGrid.modulo_status.forEach((e, i) => {
				if (e.module == 2) {
					e.DOOR.forEach((d) => {
						this.infoLocker.push(d);
						this.module.forEach((m, i) => {
							m.locker.template_module.forEach((l: any) => {
								l.forEach((t: any) => {
									if (t.text == d.door_number) {
										t.class = d.GRID.class;
										if (t.class == 'LOCKER_SCHEDULE') {
											this.tt_schedule++;
										}
										if (d.PACKAGE[0] != undefined) {
											t.order_number = Number(
												d.PACKAGE[0].order_number
											);
											t.tracking_number =
												d.PACKAGE[0].tracking_number;
											t.status_name =
												d.PACKAGE[0].status_name;
											if (
												d.PACKAGE[0].status_name ==
												'VENCIDO'
											) {
												this.tt_expired++;
												t.class = 'LOCKER_VENCIDO';
											}
										} else {
											t.order_number = 0;
											t.tracking_number = 'N/A';
										}
									}
								});
							});
						});
					});
				} else {
					e.DOOR.forEach((d) => {
						this.module.forEach((m, i) => {
							m.locker.template_module.forEach((l: any) => {
								if (l.text == d.door_number) {
									l.class = d.GRID.class;
									if (d.PACKAGE[0] != undefined) {
										l.order_number = Number(
											d.PACKAGE[0].order_number
										);
										l.tracking_number =
											d.PACKAGE[0].tracking_number;
										l.status_name = d.PACKAGE[0].status_name;
										if (d.PACKAGE[0].status_name == 'VENCIDO') {
											this.tt_expired++;
											l.class = 'LOCKER_VENCIDO';
										}
									} else {
										l.order_number = 0;
										l.tracking_number = 'N/A';
									}
								}
							});
						});
					});
				}
			});
		})

		this.disabled_ratio = 'false';
	}

	onSelectCompartment(event: any, compartment: any) {
		if (this.compartment_select_current !== undefined) {
			this.compartment_select_current.classList.remove('compartment_select');
			this.compartment_select_current.children[0].classList.add('number_select');
		}
		this.compartment_select_current = event.srcElement.classList[0] === 'mat-grid-tile-content' ? event.srcElement : event.path[1];
		this.compartment_select_current.classList.add('compartment_select');
		this.compartment_select_current.children[0].classList.add('number_select');
		this.select_compartment_available.emit(compartment);
	}

	showGrid() {}
}
