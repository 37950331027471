<div class="container-503">
	<div class="box">
		<div class="boxHeader">
			<div class="BoxTitle">
				<div class="boxText">
					<div class="errorTxt">
						Error 503
					</div>
					<div class="titleTxt">
						Este servicio no está disponible
					</div>
				</div>
				<div class="boxSubtitle">
					El servicio al que intentas acceder no está disponible por el momento. Intenta actualizando la pagina o volviendo a la pagina principal.
				</div>
			</div>
			<div class="buttonBox">
				<app-button-dashboard buttonClass="btn-md-secondary" label="Actualizar" (click)="goBack()">
				</app-button-dashboard>
				<app-button-dashboard buttonClass="btn-md-primary" label="Ir a la pantalla principal" (click)="continueLocker()">
				</app-button-dashboard>
			</div>
		</div>
		<div class="boxFooter">

		</div>
	</div>
</div>
